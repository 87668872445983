@use '@angular/material' as mat;
// theme.scss

$my-app: (
  10:  #e8fafb2e,  // sidebar
  50:  #32c5d22e,  // background
  100: #f3f6ef,  // colored-card
  200: #e16f21,  // warn
  300: #32c5d25c,
  400: #f3f6ef,
  500: #778b46,
  600: #a9be84, // primary
  700: #1f858e,
  800: #18666d,
  900: #778b46,  // accent
  A100: #ffffff,
  A200: #333333,  // text
  A400: #464646,  
  A700: #c2c2c2,
  contrast: (
    10 : #778b46,  // sidebar
    50 : #778b46,  // backgrund
    100 : #778b46, // colored-card
    200 : white,  // warn
    300 : #1f858e,
    400 : #18666d,
    500 : #f3f6ef,
    600 : #ffffff,  // primary
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,  // accent
    A100 : #778b46,
    A200 : white,
    A400 : white,
    A700 : #778b46,
  ));


  /*
  Colors :
Color 1 : #ffffff
RS gray menu  : #c2c2c2
RS Green light : #f3f6ef
RS Green : #778b46
RS blue : #5695c3
RS Text : #333333
RS Gray header : #464646
RS Green footer : #a9be84
RS Warning : #e16f21


Fonts :
Nunito Light 200
Nunito Normal 400
Nunito Bold 700
*/


$my-app-primary: mat.define-palette($my-app, 600);
$my-app-accent : mat.define-palette($my-app, 900);
$my-app-warn : mat.define-palette($my-app, 200);
$my-app-sidebar : mat.define-palette($my-app, 10); 
$my-app-background : mat.define-palette($my-app, 50);
$my-app-colored-card : mat.define-palette($my-app, 100);
$my-app-light : mat.define-palette($my-app, 200);
$my-app-text : mat.define-palette($my-app, 200);

$my-theme: mat.define-light-theme(
  $my-app-primary,
  $my-app-accent,
  $my-app-warn
);


// $sidebar : #a9be84; 
// $horizontal-bar : #778b46; 
// $separator: #f3f6ef;

// $menu: #a9be84;
// $menu-icon: #f3f6ef;
// $menu-active: #a9be84;
// $menu-icon-active: #778b46;
// $menu-text:#778b46;

// $submenu: #a9be84;
// $submenu-icon: #f3f6ef;
// $submenu-active: #a9be84;
// $submenu-icon-active: #778b46;
// $submenu-text:#778b46;

$sidebar : #778b46; 
$horizontal-bar : #a9be84; 
$separator: #a9be84;

$menu: #a9be84;
$menu-icon: #a9be84;
$menu-active: #a9be84;
$menu-icon-active: #f3f6ef;
$menu-text:#f3f6ef;

$submenu: #a9be84;
$submenu-icon: #a9be84;
$submenu-active: #a9be84;
$submenu-icon-active: #f3f6ef;
$submenu-text:#f3f6ef;



.mat-cell {
  color:dimgray !important;
}
.main-col {
  color: black !important;
  font-weight: bolder;
}