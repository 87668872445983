@use "@angular/material" as mat;
@import "./theme.scss";
@import "https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined";
//@import "https://fonts.googleapis.com/css2?family=Catamaran:wght@100;200;300;400&display=swap";
@import "https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap";
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;700&display=swap");

@include mat.core();
@include mat.all-component-themes($my-theme);

html,
body {
  height: 100%;
  margin: 0;
  font-size: 14px;
  font-family: "Nunito", "Roboto", "Helvetica Neue", sans-serif;
  color: mat.get-color-from-palette($my-app-text);
}
* {
  font-family: "Nunito", "Roboto", "Helvetica Neue", sans-serif;

}

.hidden {
  display: none;
}

.hand {
  cursor: pointer;
}

.mat-radio-label {
  margin-right: 10px !important;
}

.pull-right {
  float: right;
}

// Tables
.std-table-container {
  position: relative;
  min-height: 200px;
  flex-direction: column;
}

.std-table-container > div {
  /* these are the flex items */
  flex: 1;
}

.std-table-table-container {
  position: relative;
  //max-height: 540px;
  overflow: auto;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

.std-table-loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.std-table-rate-limit-reached {
  color: #980000;
  max-width: 360px;
  text-align: center;
}

/* Column Widths */
.mat-column-number,
.mat-column-state {
  max-width: 64px;
}

.mat-column-created {
  max-width: 124px;
}

td.mat-cell.action-col {
  width: 40px;
  padding-right: 0px;
  text-align: center;
}

mat-spinner::ng-deep {
  &.bt-spinner {
    display: inline;
    padding-right: 30px;
  }
}

.hand {
  cursor: pointer;
}

.red-text {
  color: #ed6b75;
}

.mat-error {
  color: #ed6b75 !important;
}

// hide arrows from input number
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="number"] {
  -webkit-appearance: none !important;
  -moz-appearance: textfield !important;
  margin: 0;
}

.mat-expansion-panel {
  margin-bottom: 1px !important;
  margin-top: 0px !important;
}

.mat-tooltip-info {
  background: #1f2b3de3;
  color: white !important;
  margin-right: 5px !important;
  font-size: 12px;
}

.mat-cell.logo {
  span {
    img {
      width: 100px;
      border: 1px solid lightgray;
      display: block;
    }
  }
}

.mat-mini-fab .mat-button-wrapper {
  line-height: 0px !important;
}

.tab-error-icon {
  color: #ed6b75;
  margin-top: -10px;
}

tr.mat-header-row {
  background: white !important;
}

tr.mat-row:nth-child(2n + 1):not(.expand-row) {
  background: #f6f6f6;
}

tr.example-element-row.expand-row:nth-child(4n + 1) {
  background: #f6f6f6;
}

tr.mat-row:hover:not(.mat-header-row) {
  background: #d7f3fb !important;
}

.actions-col {
  & button {
    background: transparent;
  }
}

.mat-chip.mat-standard-chip {
  background-color: #f1f1f1;
  color: rgba(0, 0, 0, 0.87);
}

.full-width {
  width: 100%;
}



// ######### quill
.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}
li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}
.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}
li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}
.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}
li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}
.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}
li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}
.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}
li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}
.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}
li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}
.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}
li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}
.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}
li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}
.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}
li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}
.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}
li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}
.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}
li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}
.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}
li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}
.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}
li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}
.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}
li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}
.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}
li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}
.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}
li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}
.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}
li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}
.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}
li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}


// align
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}
.ql-align-right {
  text-align: right;
}

// size
.ql-size-small {
  font-size: 0.75em;
}
.ql-size-large {
  font-size: 1.5em;
}
.ql-size-huge {
  font-size: 2.5em;
}



// custom classes
.ql-custom-class::before {
  content: ""!important;
}
.rs-blue-text {
  color: #3dc2ff;
}

.rs-orange-text {
  color: #ffc409;
}

.rs-green-text {
  color: #7B8F4A;
  font-size:20px;
}





// ######### quill end